import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Alert, Box, Button, Container, Grid, Stack, Typography, OutlinedInput } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import lodash from 'lodash';
import moment from 'moment';

const API_BASE_URL = 'https://dev-ball-machine.waltair.io/api/v2'; // Replace with your actual API base URL
const LABELING_USERS = ['labeler.01_user',
  'labeler.02_user',
  'labeler.03_user',
  'labeler.04_user',
  'labeler.05_user',
  'labeler.06_user',
  'labeler.07_user',
  'labeler.08_user',
  'labeler.09_user',
  'labeler.10_user',
  'Neel_Kamidi'];
const SHOT_TYPES = ['backhand',
  'forehand',
  'missed',
  'no_action',
  'not_sure',
  'overhead_smash',
  'serve',
  'unclassified'];

const PoseEstimationLabelingReview = () => {
  const [currentClip, setCurrentClip] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [labelingUser, setLabelingUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', severity: 'info' });
  const selectedShotType = searchParams.get('shot_type') || 'all';
  const {
    sessionDetails,
  } = useSelector((state) => state.auth);

  const fetchClip = async ({ direction = 'next', clipName = '' }) => {
    try {
      setIsLoading(true);
      setNotification({ message: 'Fetch in progress', severity: 'info' });
      const counter = currentClip.updated_at_epoch || 999999999999;
      let url = `${API_BASE_URL}/pose-estimation-review-${direction}-clip?counter=${counter}`;
      if (clipName) {
        url += `&fileName=${clipName}`;
      }
      if (labelingUser && labelingUser.trim() !== 'all') {
        url += `&userId=${labelingUser}`;
      }
      if (selectedShotType.trim() && selectedShotType.trim() !== 'all') {
        url += `&shotType=${selectedShotType.trim()}`;
      }
      const response = await fetch(url);
      if (!response.ok) {
        console.log('Failed to fetch clip:', response);
        const data = await response.json();
        throw new Error(lodash.get(data, 'message') || 'Failed to fetch clip');
      }
      const data = await response.json();
      // console.log('Fetched clip:', data);
      setIsLoading(false);
      setNotification({ message: 'Successfully fetched new clip!', severity: 'success' });
      return lodash.get(data, 'record', {});
    } catch (error) {
      console.error('Error fetching clip:', error);
      setIsLoading(false);
      setNotification({ message: error.message || 'Failed to fetch clip', severity: 'error' });
      return null;
    }
  };

  useEffect(() => {
    const fetchCurrentClip = async () => {
      const clip = await fetchClip({ direction: 'next' });
      if (clip) {
        setCurrentClip(clip);
      }
    };
    fetchCurrentClip();
  }, [labelingUser, selectedShotType]);

  const sendAction = async (action) => {
    try {
      setIsLoading(true);
      setNotification({ message: 'Update in progress', severity: 'info' });
      const response = await fetch(`${API_BASE_URL}/pose-estimation-classification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: currentClip.video_clip_file_name,
          classification: action,
          userId: `${sessionDetails.first_name}_${sessionDetails.last_name}`,
        }),
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error('Failed to send action');
      }
      // console.log(`Action ${action} sent successfully.`);
      setNotification({
        message: `Successfully updated the clip classification to ${action.toUpperCase()}`,
        severity: 'success'
      });
    } catch (error) {
      setIsLoading(false);
      console.error('Error sending action:', error);
      setNotification({ message: 'Failed to update!', severity: 'error' });
    }
  };

  const searchClip = async () => {
    const nextClip = await fetchClip({
      direction: 'next',
      clipName: document.getElementById('search-clip').value
    });
    if (nextClip) {
      setCurrentClip(nextClip);
    }
  };

  const handleNext = async () => {
    const nextClip = await fetchClip({ direction: 'next' });
    if (nextClip) {
      setCurrentClip(nextClip);
    }
  };

  const handlePrevious = async () => {
    const prevClip = await fetchClip({ direction: 'prev' });
    if (prevClip) {
      setCurrentClip(prevClip);
    }
  };

  const handleSelectLabelingUser = (event) => {
    setLabelingUser(event.target.value);
    setCurrentClip({ ...currentClip, updated_at_epoch: 999999999999 });
  };

  const handleSelectShotType = (event) => {
    setSearchParams({ shot_type: event.target.value });
    // navigate(`/pose-estimation-labeling-review?shot_type=${event.target.value}`);
  };

  return (
    <Container maxWidth="xl" style={{ marginTop: '2rem', textAlign: 'center' }}>
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <Grid container spacing={2}>
          {/* <Stack direction="row" justifyContent="flex-end" style={{ width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
            >
              Show filters
            </Button>
          </Stack> */}
          <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" style={{ width: '100%' }}>
            <FormControl style={{ width: '30%', margin: '1rem' }}>
              <InputLabel id="select-labeling-user">Labeling User</InputLabel>
              <Select
                labelId="select-labeling-user"
                id="labeling-user-select"
                value={labelingUser}
                label="Labeling User"
                onChange={handleSelectLabelingUser}
              >
                <MenuItem value={'all'}>All</MenuItem>
                {LABELING_USERS.map(
                  (user) => (<MenuItem value={user} key={user}>{user.toUpperCase()}</MenuItem>)
                )
                }
              </Select>
            </FormControl>
            <FormControl style={{ width: '30%', margin: '1rem' }}>
              <InputLabel id="select-shot-classification">Shot Classification</InputLabel>
              <Select
                labelId="select-shot-classification"
                id="shot-classification-select"
                value={selectedShotType}
                label="Shot Classification"
                onChange={handleSelectShotType}
              >
                <MenuItem value={'all'}>All</MenuItem>
                {SHOT_TYPES.map(
                  (shotType) => (<MenuItem value={shotType} key={shotType}>{shotType.toUpperCase()}</MenuItem>)
                )
                }
              </Select>
            </FormControl>
          </Stack>
          <Grid size={12} textAlign={'center'} style={{ width: '100%' }}>
            <Typography variant="h4" gutterBottom>
              {currentClip.video_clip_file_name}
            </Typography>
          </Grid>
          <Grid size={12} textAlign={'center'} style={{ width: '100%', margin: '1rem 0' }}>
            {
              notification && notification.message ? (
                <Alert severity={notification.severity}>{notification.message}</Alert>
              ) : null
            }
          </Grid>
          <Grid size={12} textAlign={'center'} style={{ width: '100%', margin: '1rem 0', border: '0 solid blue' }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Box width={{ xs: '100%', md: '70%' }}>
                <Stack direction="row" width="100%" spacing={4}>
                  <OutlinedInput
                    id="search-clip"
                    label="Clip name"
                    placeholder="Search by clip name"
                    sx={{ width: '60%' }}
                  />
                  <Button variant="contained" onClick={searchClip}>Search</Button>
                </Stack>
                <Stack spacing={2} sx={{ marginTop: '4rem' }}>
                  <Box>
                    <Stack spacing={2} direction="row">
                      <Typography variant="body1" gutterBottom>
                        <span style={{ fontWeight: 'bold' }}>{'Done by User: '}</span>
                        {`${currentClip.labeling_user_id}`}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <span style={{ fontWeight: 'bold' }}>{'Classification: '}</span>
                        {`${currentClip.shot_classification}`}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <span style={{ fontWeight: 'bold' }}>{'Updated At: '}</span>
                        {`${moment(currentClip.updated_at)}`}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box>
                    <video
                      autoPlay={true}
                      controls
                      muted
                      style={{ width: '100%', height: 'auto', marginBottom: '1rem' }}
                      src={currentClip.video_clip_file_url}
                    />
                  </Box>
                  <Box>
                    <Stack direction="row" spacing={8} justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePrevious}
                        disabled={isLoading}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={isLoading}
                      >
                        Next Clip
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Box width={{ xs: '100%', md: '30%' }}>
                {
                  currentClip.video_clip_file_name && (
                    <>
                      <Typography variant="h6" gutterBottom>
                        Action Classification
                      </Typography>
                      <Stack spacing={2}>
                        {['forehand', 'backhand', 'serve', 'overhead_smash',
                          'no_action', 'missed',
                          'not_sure'].map((action) => (
                          <Button
                            key={action}
                            variant="outlined"
                            color="primary"
                            disabled={isLoading}
                            onClick={() => sendAction(action)}
                          >
                            {action.replace('_', ' ').toUpperCase()}
                          </Button>
                        ))}
                      </Stack>
                      <Box sx={{ height: '2rem' }}/>
                    </>
                  )
                }
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PoseEstimationLabelingReview;
