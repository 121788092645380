import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TennisCourt from './tennis-court';
import lodash from 'lodash';

function TrackPlayerLocation({ socket, deviceId }) {
  const [playerLocation, setPlayerLocation] = useState('0');
  const [sessionId, setSessionId] = useState('');

  const receivedPlayerLocation = (data) => {
    try {
      const parsedData = JSON.parse(data);
      const {
        current_location_id: currentLocationId = '0',
        current_session_id: currentSessionId
      } = parsedData;
      if (deviceId === lodash.get(parsedData, 'device_id', '') && currentLocationId !== '0') {
        setPlayerLocation(currentLocationId);
        setSessionId(currentSessionId);
      }
    } catch (err) {
      console.error('receivedPlayerLocation error:', err);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('track:player_location', receivedPlayerLocation);

      return () => {
        socket.off('track:player_location', receivedPlayerLocation);
      };
    }
  }, [socket]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2} justifyContent="space-evenly">
          <Box width="50%">
            {
              playerLocation === '0' ? <Typography>{'Player is outsize the playing zone'}</Typography>
                : null
            }
          </Box>
          <Box width="50%"><Typography>{`Session Id: ${sessionId}`}</Typography></Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Track Player Location</Typography>
      </Grid>
      <Grid item xs={12}>
        <TennisCourt activeBlock={playerLocation}/>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginY: '2rem' }}>

        </Box>
      </Grid>
    </Grid>
  );
}

TrackPlayerLocation.propTypes = {
  socket: PropTypes.object.isRequired,
  deviceId: PropTypes.string.isRequired,
};

export default React.memo(TrackPlayerLocation);
